<template>
  <div class="part-intern constrain">
    <div class="row-12">
      <div class="md:offset-3 md:col-9">
        <div v-for="element in news" :key="element.id" class="news-element pb-8 md:pb-16">
          <div class="row-12">
            <div class="md:col-3 mb-8 md:mb-0">
              <img class="news-img" :src="element.bild.large" :alt="element.bild.alt">
            </div>
            <div class="md:col-9">
              <div class="news-text md:pl-8 text-navy" v-html="element.text" />
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    payload: Object,
  },
  computed: {
    news() {
      return this.payload.elemente;
    },
  },
  mounted() {
    // console.log(this.payload);
  },
};
</script>

<style lang="scss" scoped>
.news-element {
  border-bottom: 2px solid var(--color-light-blue);
  &:not(:last-of-type) {
    margin-bottom: 5rem;
  }
}
.news-img {
  object-fit: cover;
  width: 100%;
  max-height: px(500);
}
.news-text {
  :deep(h3) {
    margin-bottom: 2rem;
  }
  :deep(a) {
    background-image: none;
    &:hover {
      text-decoration: underline;
      color: var(--color-navy);
    }
  }
}
</style>
